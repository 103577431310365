import { useGlobalAppState } from "@/providers/app/GlobalAppStateProvider";
import { Avatar, Tooltip, Typography } from "antd";
import { AvatarProps, Flex } from "antd/lib";
import { isString } from "lodash";
import Link from "next/link";
import styled from "styled-components";

type InitialAvatarProps = AvatarProps & {
  name: string;
  displayName: boolean;
  isRedirect?: boolean;
  viewId?: string | null;
  contactId?: string;
  setDetailContactId?: any;
  ellipsisWidth?: number;
};

export function getInitials(name: string) {
  const nameArray = name.split(" ");

  if (nameArray.length === 2) {
    const firstName = nameArray[0].charAt(0).toUpperCase();
    const lastName = nameArray[nameArray.length - 1].charAt(0).toUpperCase();
    return firstName + lastName;
  }

  if (nameArray.length > 2) {
    const firstName = nameArray[0].charAt(0).toUpperCase();
    const middleName = nameArray[1].charAt(0).toUpperCase();
    const lastName = nameArray[nameArray.length - 1].charAt(0).toUpperCase();
    return firstName + middleName + lastName;
  }

  return nameArray[0].charAt(0).toUpperCase();
}

function getSuffixCount(name: string) {
  const nameArray = name.split(" ");
  if (nameArray.length > 4) {
    return nameArray[nameArray.length - 1].length;
  }
  return 0;
}

const { Text } = Typography;

const EllipsisMiddle: React.FC<{ name: string; ellipsisWidth: number }> = ({
  name,
  ellipsisWidth,
}) => {
  const suffixCount = getSuffixCount(name);
  const start = name.slice(0, name.length - suffixCount);
  const suffix = name.slice(-suffixCount).trim();
  return (
    <Text
      style={{ maxWidth: ellipsisWidth }}
      ellipsis={suffixCount !== 0 ? { suffix } : {}}>
      {start}
    </Text>
  );
};

const InitialAvatar = ({
  name,
  displayName,
  isRedirect,
  contactId,
  setDetailContactId,
  ellipsisWidth = 200,
  ...props
}: InitialAvatarProps) => {
  const userName = !isString(name)
    ? name?.full_name
      ? name?.full_name
      : name?.email
        ? name?.email
        : ""
    : name;
  return (
    <Flex gap={8} align="center">
      <Tooltip title={userName} placement="top">
        <Avatar {...props}>{getInitials(userName)}</Avatar>
      </Tooltip>
      {isRedirect ? (
        <RedirectDiv onClick={() => setDetailContactId(contactId)}>
          <EllipsisMiddle name={userName} ellipsisWidth={ellipsisWidth} />
        </RedirectDiv>
      ) : displayName ? (
        <EllipsisMiddle name={userName} ellipsisWidth={ellipsisWidth} />
      ) : (
        <></>
      )}
    </Flex>
  );
};

const RedirectDiv = styled.div`
  cursor: pointer;
  .ant-typography {
    color: #1e276c;
    font-weight: 600;
  }
`;

export default InitialAvatar;
