import { AxiosResponse } from "axios";
import {
  ContactType,
  ServerFormError,
  FilterObject,
  OrderColumnType,
  IPaginate,
} from "../types";
import { useQuery } from "@tanstack/react-query";
import { useGlobalAppState } from "@/providers/app/GlobalAppStateProvider";
import { message } from "antd";
import { useTranslations } from "next-intl";
import { useMemo } from "react";

type filterContactType = {
  page?: number;
  size?: number;
  filterGroups?: FilterObject;
  search?: string;
  orderColumn?: OrderColumnType | null;
  properties?: string[];
  kanbanGroupBy?: { [key: string]: string | undefined };
};

export const filterContactKey = "filter_contact";

function useFilterContact({
  page,
  size,
  filterGroups,
  search,
  orderColumn,
  properties = [],
  kanbanGroupBy,
}: filterContactType) {
  const { axiosInstance, API_ROUTES_COMPANY, COMPANY_ID } = useGlobalAppState();

  return useQuery<AxiosResponse<IPaginate<ContactType>>, ServerFormError>({
    queryKey: [
      filterContactKey,
      COMPANY_ID,
      {
        page,
        size,
        filterGroups,
        search,
        orderColumn,
        properties,
        kanbanGroupBy,
      },
    ],
    queryFn: () => {
      const queryBody = kanbanGroupBy
        ? {
            kanban_group_by: kanbanGroupBy,
            properties: properties,
            filter_groups: filterGroups || {
              filter_type: "AND",
              filters: [],
            },

            order_column: orderColumn || {},
          }
        : {
            properties: properties,
            filter_groups: filterGroups || {
              filter_type: "AND",
              filters: [],
            },

            order_column: orderColumn || {},
          };
      return axiosInstance.post(
        API_ROUTES_COMPANY.contact.filter_contact,
        queryBody,
        { params: { page, size, search } }
      );
    },
    enabled: COMPANY_ID !== undefined,
    retry: 1,
    refetchOnWindowFocus: false,
  });
}

export default useFilterContact;
