import useDisclosure from "@/hooks/shared/useDisclosure";
import { ProCard } from "@ant-design/pro-components";
import { Avatar, Button, Divider, Input, InputNumber, Space } from "antd";
import { useTranslations } from "next-intl";
import React from "react";
import { BsTelephoneForward } from "react-icons/bs";
import styled from "styled-components";

interface IProps {
  isExtend?: boolean;
}

function ForwardPhoneButton({ isExtend }: IProps) {
  const { isOpen, toggle } = useDisclosure();
  const t = useTranslations("call");

  return (
    <>
      <Space direction="vertical" align="center">
        <Button
          size={isExtend ? "large" : "middle"}
          shape="circle"
          icon={<BsTelephoneForward />}
          onClick={toggle}
        />
        {isExtend && <span>{t("forward_call")}</span>}
      </Space>
      <div
        style={{ width: 320, display: isOpen ? "block" : "none" }}
        className="position-absolute bottom-0 end-100">
        <StyledProCard boxShadow bodyStyle={{ padding: 12 }}>
          <StyledInputNumber type="number" />
          <div
            style={{ maxHeight: isExtend ? 200 : 72, overflowY: "scroll" }}
            className="d-flex flex-column mt-2 gap-2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <Avatar size="small" className="me-2" />
                <span>Nguyễn Quân</span>
                <Divider type="vertical" />
                <span>12341111</span>
              </div>
              <Button
                style={{ backgroundColor: "#23D3D3", color: "#fff" }}
                icon={<BsTelephoneForward />}
              />
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <Avatar size="small" className="me-2" />
                <span>Nguyễn Quân</span>
                <Divider type="vertical" />
                <span>12341111</span>
              </div>
              <Button
                style={{ backgroundColor: "#23D3D3", color: "#fff" }}
                icon={<BsTelephoneForward />}
              />
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <Avatar size="small" className="me-2" />
                <span>Nguyễn Quân</span>
                <Divider type="vertical" />
                <span>12341111</span>
              </div>
              <Button
                style={{ backgroundColor: "#23D3D3", color: "#fff" }}
                icon={<BsTelephoneForward />}
              />
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <Avatar size="small" className="me-2" />
                <span>Nguyễn Quân</span>
                <Divider type="vertical" />
                <span>12341111</span>
              </div>
              <Button
                style={{ backgroundColor: "#23D3D3", color: "#fff" }}
                icon={<BsTelephoneForward />}
              />
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <Avatar size="small" className="me-2" />
                <span>Nguyễn Quân</span>
                <Divider type="vertical" />
                <span>12341111</span>
              </div>
              <Button
                style={{ backgroundColor: "#23D3D3", color: "#fff" }}
                icon={<BsTelephoneForward />}
              />
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <Avatar size="small" className="me-2" />
                <span>Nguyễn Quân</span>
                <Divider type="vertical" />
                <span>12341111</span>
              </div>
              <Button
                style={{ backgroundColor: "#23D3D3", color: "#fff" }}
                icon={<BsTelephoneForward />}
              />
            </div>
          </div>
        </StyledProCard>
      </div>
    </>
  );
}

const StyledProCard = styled(ProCard)`
  background-color: #fff !important;
`;

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
  .ant-input-number-handler-wrap {
    display: none;
  }
`;

export default ForwardPhoneButton;
