import { useGlobalAppState } from "@/providers/app/GlobalAppStateProvider";
import { useQuery } from "@tanstack/react-query";

const useGetContactInfoKey = "get_contact_info_key";

interface IParam {
  CONTACT_ID: string;
}

const useGetContactInfo = ({ CONTACT_ID }: IParam) => {
  const { axiosInstance, COMPANY_ID, API_ROUTES_COMPANY } = useGlobalAppState();

  return useQuery({
    queryKey: [useGetContactInfoKey, COMPANY_ID, CONTACT_ID],
    queryFn: () =>
      axiosInstance.get(
        API_ROUTES_COMPANY.contact.contact_info_detail(CONTACT_ID)
      ),
    enabled: !!CONTACT_ID && CONTACT_ID !== "",
    refetchOnWindowFocus: false,
  });
};

export default useGetContactInfo;
