import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { IAgent, ServerFormError } from "../types";
import { useGlobalAppState } from "@/providers/app/GlobalAppStateProvider";

export const useMyAgentKey = "agent";

function useMyAgent() {
  const { API_ROUTES_COMPANY, axiosInstance, COMPANY_ID } = useGlobalAppState();
  return useQuery<AxiosResponse<IAgent>, ServerFormError>({
    queryKey: [useMyAgentKey, COMPANY_ID],
    queryFn: () => {
      return axiosInstance.get(API_ROUTES_COMPANY.agent.me);
    },
    retry: false,
  });
}

export default useMyAgent;
