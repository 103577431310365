import { Avatar, Button, Space, theme } from "antd";
import React from "react";
import { UserOutlined, PhoneOutlined } from "@ant-design/icons";
import Image from "next/image";
import { BsTelephoneX } from "react-icons/bs";
import { useTranslations } from "next-intl";
import useFilterContact from "@/services/contact/useFilterContact";
import { getInitials } from "@/components/shared/InitialAvatar";
import useGetContactInfo from "@/services/contact/useGetContactInfo";
import { useMutation } from "@tanstack/react-query";
import { usePhoneFeatures } from "@/providers/app/PhoneFeaturesProvider";
import { useEnvironment } from "@/providers/app/EnvironmentProvider";
import {
  PopupBeforeCallCollapse,
  PopupInCallCollapse,
} from "@/providers/app/PhoneFeatures/components/PopupCollapse";
import { GlobalBorderRadiusPx } from "@/utils/app/constants";

interface IProps {
  phoneNumber: string;
  LineNumber: number;
  collapsed?: boolean;
}

function InComingCall({ phoneNumber, LineNumber, collapsed = false }: IProps) {
  const t = useTranslations("call");
  const {
    token: { colorPrimary },
  } = theme.useToken();
  const { phoneContextRef, activeLineNumber } = usePhoneFeatures();
  const { PHONE_IFRAME_SYNC_SPEED } = useEnvironment();
  const { data: listContacts, refetch } = useFilterContact({
    page: 1,
    size: 1,
    filterGroups: undefined,
    search: phoneNumber,
    orderColumn: null,
    properties: [],
  });
  const contact = listContacts?.data?.results?.[0];
  const CONTACT_ID = contact?.id;
  const { data: contactInfo, isLoading } = useGetContactInfo({
    CONTACT_ID: CONTACT_ID as string,
  });
  const customerName = contactInfo?.data?.properties?.find(
    (e) => e.slug === "customer_name"
  )?.value;

  const isIdentified = !!customerName;

  const { mutateAsync: RejectCall, isPending: isRejecting } = useMutation({
    mutationFn: async () => {
      phoneContextRef?.current?.RejectCall(LineNumber);
      await new Promise((resolve) =>
        setTimeout(resolve, PHONE_IFRAME_SYNC_SPEED)
      );
    },
  });

  const { mutateAsync: AnswerAudioCall, isPending: isAnswering } = useMutation({
    mutationFn: async () => {
      phoneContextRef?.current?.AnswerAudioCall(LineNumber);
      await new Promise((resolve) =>
        setTimeout(resolve, PHONE_IFRAME_SYNC_SPEED)
      );
    },
  });

  if (collapsed) {
    return (
      <div className="p-2">
        <div
          className="p-2"
          style={{
            border: `2px solid ${colorPrimary}`,
            borderRadius: GlobalBorderRadiusPx,
          }}>
          <div className="d-flex flex-row gx-2 align-items-center justify-content-between">
            <div className="col-8">
              <h6 style={{ margin: 0, marginRight: "8px" }}>
                {isIdentified ? customerName?.toString() : t("un_identified")}
              </h6>

              <div>{phoneNumber}</div>
              <div style={{ color: "#8C8C8C" }}>{t("calling_to")} </div>
            </div>
            <div className="col-4 d-flex flex-row">
              <Button
                block
                type="primary"
                size="large"
                loading={isRejecting}
                onClick={() => RejectCall()}
                danger
                icon={<BsTelephoneX />}
              />
              <div style={{ width: "12px" }} />
              <Button
                block
                type="primary"
                loading={isAnswering}
                onClick={() => AnswerAudioCall()}
                size="large"
                icon={<PhoneOutlined />}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <Space className="p-4 w-100 justify-content-center">
        <Avatar size={64} icon={isIdentified ? "" : <UserOutlined />}>
          {getInitials(customerName?.toString() || "K")}
        </Avatar>
        <div>
          <h6>
            {isIdentified ? customerName?.toString() : t("un_identified")}
          </h6>
          <span>{phoneNumber}</span>
        </div>
      </Space>
      <h6 className="text-center">
        <span style={{ color: "#8C8C8C" }}>{t("calling_to")} </span>
      </h6>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: 120,
          margin: "32px 0px",
        }}>
        <Image
          src="/phone.gif"
          layout="fill"
          objectFit="contain"
          alt="ringingPhone"
        />
      </div>
      <div className="row gx-2 mx-3 mb-5">
        <div className="col-6">
          <Button
            type="primary"
            loading={isRejecting}
            className="w-100 mt-3"
            onClick={() => RejectCall()}
            danger
            icon={<BsTelephoneX />}>
            {t("reject")}
          </Button>
        </div>
        <div className="col-6">
          <Button
            type="primary"
            loading={isAnswering}
            onClick={() => AnswerAudioCall()}
            className="w-100 mt-3"
            icon={<PhoneOutlined />}>
            {t("pick_up")}
          </Button>
        </div>
      </div>
    </>
  );
}

export default InComingCall;
