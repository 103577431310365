"use client";

import styled from "styled-components";
import { ProCard } from "@ant-design/pro-components";
// import InComingCall from "@/providers/app/PhoneFeatures/components/InComingCall";
import React from "react";
import { Button, Result, theme } from "antd";
import {
  ArrowDownOutlined,
  CloseOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import BeforeCall from "@/providers/app/PhoneFeatures/components/BeforeCall";
import { UnmountClosed } from "react-collapse";
import LineCall from "@/providers/app/PhoneFeatures/components/LineCall";
import { usePhoneFeatures } from "@/providers/app/PhoneFeaturesProvider";
import { useFloatingWindow } from "@/providers/app/FloatingWindowProvider";
import { PopupBeforeCallCollapse } from "@/providers/app/PhoneFeatures/components/PopupCollapse";
// import BeforeCall from "@/providers/app/PhoneFeatures/components/BeforeCall";

const StyledProCard = styled(ProCard)<{ primaryColor?: string }>`
  /* overflow: hidden; */
  .ant-pro-card-header {
    background-color: ${(props) => props.primaryColor};
    padding: 10px 12px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .ant-pro-card-title {
    color: #ffffff;
    overflow: hidden;
  }

  height: 100%;
  max-height: ${(props) => props.maxHeight}px;
  overflow-y: scroll;

  -webkit-transition: max-height 0.5s;
  -moz-transition: max-height 0.5s;
  -ms-transition: max-height 0.5s;
  -o-transition: max-height 0.5s;
  transition: max-height 0.5s;
`;

interface IProps {
  defaultValues?: {
    extraHeaders?: string[];
    phoneNumber?: string;
  };
}

const PhoneWindow = ({ defaultValues }: IProps) => {
  const {
    token: { colorPrimary },
  } = theme.useToken();
  const { Lines, collapsed, toggleCollapse, windowHeight, enabledIframe } =
    usePhoneFeatures();
  const { close } = useFloatingWindow();

  const closable = Lines?.length !== 0;

  return (
    <StyledProCard
      // boxShadow
      primaryColor={colorPrimary}
      title="Điện thoại"
      maxHeight={windowHeight}
      extra={
        <div className="d-flex flex-row gap-2">
          <Button
            onClick={toggleCollapse}
            type="text"
            icon={
              collapsed ? (
                <UpOutlined style={{ color: "white" }} />
              ) : (
                <DownOutlined style={{ color: "white" }} />
              )
            }>
            <span style={{ color: "white" }}>
              {collapsed ? "Mở rộng" : "Thu gọn"}
            </span>
          </Button>
          <Button
            disabled={Lines?.length !== 0}
            onClick={close}
            type="link"
            icon={
              <CloseOutlined
                style={{ color: "white", opacity: closable ? 1.0 : 0.5 }}
              />
            }
          />
        </div>
      }
      bodyStyle={{ padding: 0 }}>
      <UnmountClosed isOpened={enabledIframe}>
        <UnmountClosed isOpened={Lines?.length !== 0}>
          {Lines?.map((line) => (
            <LineCall
              key={`line_${line.LineNumber}`}
              line={line}
              collapsed={collapsed}
            />
          ))}
        </UnmountClosed>
        <UnmountClosed isOpened={Lines?.length === 0}>
          <UnmountClosed isOpened={!collapsed}>
            <BeforeCall
              phoneNumber={defaultValues?.phoneNumber}
              extraHeaders={defaultValues?.extraHeaders}
            />
          </UnmountClosed>
          <UnmountClosed isOpened={collapsed}>
            <PopupBeforeCallCollapse phoneNumber={defaultValues?.phoneNumber} />
          </UnmountClosed>
        </UnmountClosed>
      </UnmountClosed>
      {!enabledIframe && (
        <Result status="error" title="Phone iframe not enabled" />
      )}
    </StyledProCard>
  );
};

export default PhoneWindow;
